import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Portal from '@material-ui/core/Portal';
import useResizeObserver from '../hooks/useResizeObserver';

const PortalContainer = ({ on, children, html, disablePortal, className, ...rest }) => {
  const [node, size] = useResizeObserver(on);

  const style = useMemo(
    () => size && ({
      position: 'absolute',
      width: size.width,
      top: size.bottom + window.scrollY || 0,
      left: size.left,
      zIndex: 1000000
    }),
    [size]
  );

  return (
    <>
      {React.cloneElement(children, {
        ...rest,
        ref: node
      })}
      <Portal disablePortal={disablePortal}>
        <div style={disablePortal ? {} : style}>
          <div className={className}>
            {html}
          </div>
        </div>
      </Portal>
    </>
  )
};

PortalContainer.propTypes = {
  children: PropTypes.element.isRequired,
  html: PropTypes.node.isRequired,
  disablePortal: PropTypes.bool,
  on: PropTypes.bool,
  className: PropTypes.string,
};

PortalContainer.defaultProps = {
  className: null,
  disablePortal: false,
  on: false
};

export default PortalContainer;
