import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import TruncateText from '../TruncateText';

import styles from './Button2.module.scss';

export const Button2 = React.forwardRef((
  {
    children,
    size,
    theme,
    icon,
    disabled,
    label,
    type,
    reverse,
    onClick,
    className,
    iconTheme,
    iconActive,
    iconClassName,
    iconFill,
    iconActiveFill,
    iconSize,
    width,
    to,
    tooltip,
    fullWidth,
    active,
    ...rest
  }, ref) => {
  const classNames = cn(
    styles.button,
    styles[size],
    styles[theme],
    {
      [styles.withIcon]: icon && (label || children),
      [styles.reverse]: reverse,
      [styles.onlyIcon]: !children && !label,
      [styles.fullWidth]: fullWidth,
      [styles.disabled]: disabled,
      [styles.active]: active,
    },
    className
  );

  const cnIcon = cn(styles.icon, iconClassName, {
    [styles.disabled]: disabled,
  })

  const renderIcon = useMemo(
    () => icon && (
      <Icon
        label={icon}
        className={cnIcon}
        theme={iconTheme}
        active={iconActive}
        fill={iconFill}
        activeFill={iconActiveFill}
        size={iconSize}
      />
    ),
    [icon, iconActive, iconTheme, cnIcon, iconFill, iconActiveFill, iconSize]
  );

  const buttonStyle = useMemo(
    () => ({
      ...rest.style,
      width: !width ? 'auto' : width,
      pointerEvents: disabled ? 'none' : 'initial'
    }),
    [disabled, width, rest.style]
  )

  const hasLink = useMemo(
    () => to
      ? (
        <Link to={to}>
          {children}
        </Link>
      )
      : children,
    [children, to]
  );

  const getLabel = useMemo(
    () => typeof label === 'object'
      ? label
      : (
        <TruncateText>
          {label}
        </TruncateText>
      ),
    [label]
  );

  const button = useMemo(
    () => {
      return (
        <button
          ref={ref}
          style={buttonStyle}
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={classNames}
          {...rest}
        >
          {renderIcon}
          <span className={styles.content}>
            {children && hasLink}
            {label && getLabel}
           </span>
        </button>
      )
    },
    [disabled, children, label, getLabel, onClick, type, ref, rest, classNames, renderIcon, hasLink, buttonStyle]
  )

  if (tooltip || (tooltip && disabled)) {
    return (
      <Tooltip content={tooltip}>
        <span className={styles.spanWrapper}>
          {button}
        </span>
      </Tooltip>
    );
  }

  return button;
});

Button2.displayName = 'Button2';

Button2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'tertiaryReverse']),
  iconTheme: PropTypes.oneOf(['solid', 'thin', 'regular']),
  iconSize: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  iconActiveFill: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  iconActive: PropTypes.bool,
  type: PropTypes.string,
  reverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  width: PropTypes.string,
  to: PropTypes.string,
  tooltip: PropTypes.string,
};

Button2.defaultProps = {
  children: null,
  icon: null,
  label: null,
  size: 'medium',
  iconTheme: 'regular',
  iconActive: false,
  iconFill: null,
  iconActiveFill: null,
  fullWidth: false,
  theme: 'primary',
  className: null,
  iconClassName: null,
  width: null,
  to: null,
  tooltip: null,
};

export default memo(Button2);
