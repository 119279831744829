import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../SelectionControls/Checkbox';
import Button2 from '../Button2';

import styles from './Modal2.module.scss';


const Footer2 = ( { action, ...rest }) => {
  const { primary, secondary, tertiary, checkbox } = action;

  const displayPrimaryButton = useMemo(
    () => primary && primary.title && (
      <Button2
        onClick={primary.onClick}
        disabled={primary.disabled}
        tooltip={primary.tooltip}
        {...primary}
      >
        {primary.title}
      </Button2>
    ),
    [primary]
  );

  const displaySecondaryButton = useMemo(
    () => secondary && secondary.title && (
      <Button2
        theme="tertiary"
        onClick={secondary.onClick}
        disabled={secondary.disabled}
        tooltip={secondary.tooltip}
        {...secondary}
      >
        {secondary.title}
      </Button2>
    ),
    [secondary]
  );

  const displayTertiaryButton = useMemo(
    () => tertiary && tertiary.title && (
      <div className={styles.tertiary}>
        <Button2
          theme="secondary"
          onClick={tertiary.onClick}
          disabled={tertiary.disabled}
          tooltip={tertiary.tooltip}
          {...tertiary}
        >
          {tertiary.title}
        </Button2>
      </div>
    ),
    [tertiary]
  );

  const displayCheckbox = useMemo(
    () => checkbox && (
      <Checkbox
        className={styles.checkbox}
        theme="KolLab"
        label={checkbox.title}
        id={checkbox.title}
        name={checkbox.title}
        checked={checkbox.checked}
        onChange={checkbox.onChange}
        disabled={checkbox.disabled}
        contentTooltip={checkbox.tooltip}
      />
    ),
    [checkbox]
  );

  return (
    <div className={styles.footer} {...rest}>
      {displayCheckbox}
      {displayTertiaryButton}
      {displaySecondaryButton}
      {displayPrimaryButton}
    </div>
  )
};

Footer2.propTypes = {
  action: PropTypes.shape({
    primary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    }),
    secondary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    }),
    tertiary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    })
  })
}

Footer2.defaultProps = {
  action: {
    primary: null,
    secondary: null,
    tertiary: null,
  }
}

export default memo(Footer2);
