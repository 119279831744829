import 'react-dates/initialize';
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import uniqId from 'uniqid';

import Icon from '../Icon';
import Label from '../Label';

import './date.scss';
import styles from './Date.module.scss';

const Date = props => {
  const {
    id, date, appendToBody, className, onDatesChange, onDateChange, singleDate, dateLabel, dateLabelIcon,
    startDate, endDate, startDateLabel, endDateLabel, startDateLabelIcon, endDateLabelIcon, isOutsideRange, renderMonthElement,
    disabled, size, displayFormat, startDatePlaceholder, endDatePlaceholder, startDateId, endDateId, required, startRequired, endRequired,
    ...rest
  } = props;

  const [focusedInput, setFocusedInput] = useState(null);
  const [focused, setFocused] = useState(false);

  const handleFocusChange = useCallback(
    focusedInput => setFocusedInput(focusedInput),
    []
  );

  const handleSingleFocusChange = useCallback(
    ({ focused }) => setFocused(focused),
    []
  );

  const handleRenderMonthElement = useCallback(
    ({ month, onYearSelect }) => {
      return (
        <div className={styles.selectYearContainer}>
          <Icon
            label="chevron-double-left"
            onClick={() => onYearSelect(month, moment(month).year() - 1)}
            fill="#82888a"
            isButton
          />
          <span>{moment(month).format('MMM YYYY')}</span>
          <Icon
            label="chevron-double-right"
            onClick={() => onYearSelect(month, moment(month).year() + 1)}
            fill="#82888a"
            isButton
          />
        </div>
      )
    },
    []
  );

  const cnDate = cn(styles.date, 'kolkit_date', styles[size], className, {
    [styles.singleDate]: singleDate
  });

  return singleDate ? (
    <div className={cnDate} {...rest}>
      <Label label={dateLabel} icon={dateLabelIcon} required={required} />
      <SingleDatePicker
        {...props}
        id={id}
        className={cnDate}
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={handleSingleFocusChange}
        numberOfMonths={1}
        renderMonthElement={handleRenderMonthElement}
        displayFormat={displayFormat}
      />
    </div>
  ) : (
    <div className={cnDate} {...rest}>
      <div className={styles.labels}>
        <Label label={startDateLabel} icon={startDateLabelIcon} required={startRequired} />
        <Label label={endDateLabel} icon={endDateLabelIcon} required={endRequired} />
      </div>
      <DateRangePicker
        {...props}
        className={cnDate}
        startDateId={startDateId}
        endDateId={endDateId}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        onFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        disabled={disabled}
        firstDayOfWeek={1}
        isOutsideRange={isOutsideRange}
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        displayFormat={displayFormat}
        hideKeyboardShortcutsPanel
        customArrowIcon={<></>}
        appendToBody={appendToBody}
      />
    </div>
  )
};

Date.displayName = 'Date';

Date.propTypes = {
  appendToBody: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  dateLabel: PropTypes.string,
  dateLabelIcon: PropTypes.string,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  startDateLabelIcon: PropTypes.string,
  endDateLabelIcon: PropTypes.string,
  onDatesChange: PropTypes.func,
  onDateChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  startRequired: PropTypes.bool,
  endRequired: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
  displayFormat: PropTypes.any,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  singleDate: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  renderMonthElement: PropTypes.func
};

Date.defaultProps = {
  id: uniqId(),
  appendToBody: false,
  className: null,
  date: null,
  dateLabel: null,
  dateLabelIcon: null,
  onDatesChange: () => {},
  onDateChange: () => {},
  startDateLabel: null,
  endDateLabel: null,
  startDateLabelIcon: null,
  endDateLabelIcon: null,
  disabled: false,
  required: false,
  startRequired: false,
  endRequired: false,
  size: 'default',
  displayFormat: 'DD/MM/YYYY',
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  startDateId: uniqId(),
  endDateId: uniqId(),
  singleDate: false,
  isOutsideRange: () => false,
  renderMonthElement: null
}

export default memo(Date);
