/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import cn from 'classnames';
import Icon from '../Icon';
import Loader from '../Loader';
import Footer from './Footer';
import ModalFooter from './ModalFooter';

import styles from './Modal.module.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({
  children, on, title, onClick, action, className, fullScreen, loading, disableBackdropClick,
  disableEscapeKeyDown, modalFooter, width, subTitle, classNameSubtitle, theme, ...rest
}) => {
  const cnSubtitle = cn(styles.subTitle, classNameSubtitle);

  const handleClick = useCallback(
    () => onClick(),
    [onClick]
  );

  const renderHeader = useMemo(
    () => {
      return (
        <>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subTitle && <span className={cnSubtitle}>{subTitle}</span>}
        </>
      )
    },
    [title, subTitle, cnSubtitle]
  );

  const renderFooter = useMemo(
    () => action && (
      <Footer action={action} />
    ),
    [action]
  );

  const renderModalFooter = useMemo(
    () => modalFooter && (
      <ModalFooter>
        {modalFooter}
      </ModalFooter>
    ),
    [modalFooter]
  )

  const cnModal = cn(styles.modal, styles[width], className, {
    [styles.fullScreen]: fullScreen,
    [styles.kolsquare]: theme === 'kolsquare',
  });

  return (
    <Dialog
      onClose={handleClick}
      open={on}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...rest}
    >
      <div className={cnModal}>
        <Icon
          label="times"
          className={styles.icon}
          onClick={handleClick}
          size="small"
          isButton
        />
        {renderHeader}
        <div className={styles.body}>
          <div className={styles.content}>
            {!loading ? children : <Loader />}
          </div>
        </div>
        {renderFooter}
        {renderModalFooter}
      </div>
    </Dialog>
  )
};

Modal.displayName = 'Modal';

Modal.propTypes = {
  action: PropTypes.shape({
    primary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.any,
      disabled: PropTypes.bool
    }),
    secondary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.any,
      disabled: PropTypes.bool
    })
  }),
  on: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fullScreen: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  modalFooter: PropTypes.any,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  width: PropTypes.oneOf(['default', 'auto'])
};

Modal.defaultProps = {
  action: null,
  title: null,
  subTitle: null,
  fullScreen: false,
  loading: false,
  className: null,
  modalFooter: null,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  width: 'default'
};

export default memo(Modal);
