import React, { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tag from './Tag';
import Select from '../Select';
import { StringOrNumber, StringOrReact } from '../utils/propTypes';

import styles from './SelectTag.module.scss';

let newSelected = [];

class SelectTag extends PureComponent {
  state = { hasBeenFocused: false };

  getItemFromValue = value => {
    const { dataset } = this.props;
    const ob = dataset.find(d => d?.value?.toString() === value?.toString());
    if (!ob) return null;
    return ob;
  };

  onChangeSelect = ({ value }) => {
    const { onChange, selected, id } = this.props;
    this.onFocus();

    if (value === null || value === '') return null;

    const ob = this.getItemFromValue(value);

    if (!ob) return null;

    if (!selected.includes(ob.value)) {
      newSelected = [...selected, ob.value];
    } else {
      newSelected = selected.filter(s => s !== ob.value);
    }

    onChange({
      id,
      item: ob,
      selected: newSelected,
      type: 'selectTag'
    });
  };

  onFocus = () => {
    if (!this.state.hasBeenFocused) {
      this.setState({ hasBeenFocused: true })
    }
  };

  onTabPressed = e => {
    if (e.value !== null && e.value !== '') {
      e.preventDefault();
    }
  };

  render() {
    const { className, selected, disabled, maxTags, inPortal, useNative, size, ...rest } = this.props;
    const { hasBeenFocused } = this.state;

    const isFull = maxTags > 0 && selected?.length >= maxTags;

    const cnSelectTag = cn(styles.selectTag, className);

    return (
      <div className={cnSelectTag}>
        <Select
          {...rest}
          value=""
          className={styles.select}
          error={hasBeenFocused && selected.length === 0}
          onTabPressed={this.onTabPressed}
          onChange={this.onChangeSelect}
          onFocus={this.onFocus}
          labelAsPlaceholder
          removeSearchOnClick
          disabled={disabled || isFull}
          withMask={!isFull}
          inPortal={inPortal}
          useNative={useNative}
          size={size}
          controlledError
        />
        {selected?.length > 0 && (
          <div className={styles.selectTagContainer}>
            <ul>
              {Children.toArray(selected?.map(s => (
                <Tag
                  label={this.getItemFromValue(s).label}
                  value={s}
                  disabled={disabled}
                  onClickDelete={value => this.onChangeSelect({ value })}
                />
              )))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

SelectTag.displayName = 'SelectTag';

SelectTag.defaultProps = {
  id: null,
  useNative: false,
  maxListItems: -1,
  disabled: false,
  required: false,
  placeholder: '',
  errorRequiredText: `This field is required`,
  label: ``,
  defaultOptionLabel: '',
  className: ``,
  sort: true,
  pinned: [],
  selected: [],
  maxTags: -1,
  onChange: null,
  inPortal: false,
  size: 'default'
};

SelectTag.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.arrayOf(StringOrNumber),
  dataset: PropTypes.arrayOf(PropTypes.shape({
    value: StringOrNumber.isRequired,
    label: StringOrReact.isRequired,
    react: StringOrReact,
    icon: PropTypes.element,
    disabled: PropTypes.bool
  })).isRequired,
  onChange: PropTypes.func,
  pinned: PropTypes.arrayOf(StringOrNumber),
  useNative: PropTypes.bool,
  maxListItems: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  errorRequiredText: StringOrReact,
  label: StringOrReact,
  defaultOptionLabel: PropTypes.string,
  className: PropTypes.string,
  sort: PropTypes.bool,
  maxTags: PropTypes.number,
  inPortal: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big'])
};

export default SelectTag;
