import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Avatar.module.scss';

const Avatar = ({ className, src, alt, size, rounded }) => {
  const classNames = cn(styles.avatar, styles[size], className, {
    [styles.rounded]: rounded
  });

  return (
    <div role="img" aria-label="Avatar" className={classNames}>
      <img src={src} alt={alt} />
    </div>
  );
};

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'big'])
};

Avatar.defaultProps = {
  className: '',
  size: 'medium',
  rounded: true,
  alt: 'avatar'
};

export default memo(Avatar);
