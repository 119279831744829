const isEventOutside = (e, dom) => {
  let current = e.target;

  const componentNode = dom;

  while (current.parentNode) {
    if (current === componentNode) {
      return false;
    }
    current = current.parentNode;
  }

  return current === document;
};

export default isEventOutside;
