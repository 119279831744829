/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Icon from '../../Icon';

import styles from './GitLog.module.scss';

const GitLog = ({ data, branchBack, ...rest }) => {
  const [git, setGit] = useState('');
  const [branch, setBranch] = useState('');

  useEffect(() => {
    fetch(data)
      .then(result => result.text())
      .then(data => setGit(data))
  }, [data]);

  useEffect(() => {
    const start = git.indexOf('->');
    const end = git.indexOf(',');
    setBranch(git.slice(start + 3, end));
  }, [git]);

  return (
    <Icon
      {...rest}
      className={styles.gitIcon}
      label="code-branch"
      tooltip={`Front: ${branch} | Back: ${branchBack}`}
    />
  )
};

export default GitLog;
