/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import cn from 'classnames';
import Icon from '../Icon';
import Footer from '../Modal/Footer';

import styles from './Alert.module.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Alert = ({ children, on, title, onClick, className, iconTitle, primaryTitle, primaryAction, secondaryAction, secondaryTitle, ...rest }) => {
  const handleClick = useCallback(
    () => {
      secondaryAction && secondaryAction();
      onClick && onClick()
    },
    [onClick, secondaryAction]
  );

  const action = {
    primary: {
      title: primaryTitle,
      onClick: primaryAction
    },
    secondary: {
      title: secondaryTitle,
      onClick: handleClick
    }
  };

  const renderHeader = useMemo(
    () => title && (
      <h2 className={styles.title}>
        {iconTitle !== null && <Icon width={24} label={iconTitle} />}
        {title}
      </h2>
    ),
    [title, iconTitle]
  );

  const renderFooter = useMemo(
    () => (
      <Footer action={action} className={styles.footer} />
    ),
    [action]
  );

  const cnAlert = cn(styles.alert, className);

  return (
    <Dialog
      onClose={handleClick}
      open={on}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}>
      <div className={cnAlert}>
        {renderHeader}
        <div className={styles.body}>
          <div className={styles.content}>
            {children}
          </div>
        </div>
        {renderFooter}
      </div>
    </Dialog>
  )
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  on: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  iconTitle: PropTypes.string,
  className: PropTypes.string,
  primaryTitle: PropTypes.string,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func
};

Alert.defaultProps = {
  title: null,
  iconTitle: 'exclamation-triangle',
  className: null,
  onClick: null,
  primaryTitle: null,
  primaryAction: () => {},
  secondaryAction: () => {}
};

export default memo(Alert);
