import React, { Children, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from './LineChartComparison.module.scss';

const style = (percent, bg) => ({
  width: `${percent}%`,
  borderColor: bg,
  height: '100%'
});

const LineChartComparison = ({ data, average, legend, className, ...rest }) => {
  const sortedData = useMemo(
    () =>
      [...data, { ...average, average: true, color: styles.averageColor }].sort(
        (a, b) => a.percent - b.percent
      ),
    [data, average]
  );

  const renderBar = useMemo(
    () =>
      Children.toArray(
        sortedData.reduce(
          (items, item, index) => [
            ...items,
            <div
              className={styles.segment}
              style={style(
                item.percent - (sortedData[index - 1]?.percent || 0),
                item.color
              )}
            />
          ],
          []
        )
      ),
    [sortedData]
  );

  const tooltipContent = useMemo(() => {
    return (
      <div className={styles.tooltip}>
        {React.Children.toArray(
          sortedData
            .slice()
            .reverse()
            .map(item => {
              const cnLabel = cn(styles.label, {
                [styles.highlight]: item.highlight
              });
              return (
                <div className={styles.item}>
                  <span
                    className={styles.dot}
                    style={{ backgroundColor: item.color }}
                  />
                  <span className={cnLabel}>{item.label}</span>
                  <span className={styles.value}>{item.value}</span>
                </div>
              );
            })
            .slice(3)
        )}
      </div>
    );
  }, [sortedData]);

  const renderLabel = useMemo(
    () =>
      legend ? (
        <div className={styles.labelWrapper}>
          {Children.toArray(
            sortedData
              .slice()
              .reverse()
              .map((item, index) => {
                const cnLabelText = cn(styles.labelText, {
                  [styles.highlight]: item.highlight
                });
                return index < 3 ? (
                  <div className={styles.label}>
                    <div>
                      <span
                        className={styles.dot}
                        style={{ backgroundColor: item.color }}
                      />
                    </div>
                    <div>
                      <div className={cnLabelText}>{item.label}</div>
                      <div className={styles.labelSubText}>{item.value}</div>
                    </div>
                    {index === 2 && sortedData.length > 3 && (
                      <Tooltip content={tooltipContent}>
                        <span className={styles.plusLabel}>
                          {`+${sortedData.length - 3}`}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                ) : null;
              })
          )}
        </div>
      ) : null,
    [sortedData, tooltipContent, legend]
  );

  const cnBarChart = cn(styles.container, className);

  return (
    <div className={cnBarChart} {...rest}>
      <div className={styles.wrapper}>
        {renderLabel}
        <div className={styles.bar}>
          <div className={styles.extremities} />
          <div className={styles.line} />
          {renderBar}
        </div>
      </div>
    </div>
  );
};

LineChartComparison.displayName = 'LineChartComparison';

LineChartComparison.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.number,
      color: PropTypes.string,
      value: PropTypes.string,
      highlight: PropTypes.bool
    })
  ).isRequired,
  average: PropTypes.shape({
    label: PropTypes.string,
    percent: PropTypes.number,
    value: PropTypes.string
  }).isRequired,
  legend: PropTypes.bool,
  className: PropTypes.string
};

LineChartComparison.defaultProps = {
  legend: true,
  className: null
};

export default memo(LineChartComparison);
