import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { animated, useSpring } from 'react-spring';
import useMeasure from '../../hooks/useMeasure';
import styles from './HeightTransition.module.scss';

const HeightTransition = ({ children, className, on, minHeight, config }) => {
  const [size, ref] = useMeasure();
  const getStartHeight = !minHeight ? 0 : minHeight;

  const props = useSpring({
    height: on ? size.height : getStartHeight,
    config: !config
      ? {
        tension: 350,
        friction: 30,
        mass: 0.6,
        precision: 1
      }
      : {...config}
  })

  const cnContainer = cn(styles.container, className);

  return (
    <animated.div className={cnContainer} style={props}>
      <animated.div ref={ref} className={styles.wrapper}>
        {children}
      </animated.div>
    </animated.div>
  );
};

HeightTransition.displayName = 'HeightTransition';

HeightTransition.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  on: PropTypes.bool.isRequired,
  config: PropTypes.shape({}),
  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

HeightTransition.defaultProps = {
  className: undefined,
  height: null,
  config: null
}

export default memo(HeightTransition);
