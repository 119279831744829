import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Icon from '../Icon';
import Loader from '../Loader';
import Tooltip from '../Tooltip';

import styles from './Button.module.scss';

export const Button = React.forwardRef((
  {
    children,
    size,
    theme,
    icon,
    disabled,
    label,
    type,
    reverse,
    onClick,
    className,
    iconTheme,
    iconActive,
    iconClassName,
    iconFill,
    width,
    loading,
    to,
    tooltip,
    fullWidth,
    ...rest
  }, ref) => {
  const classNames = cn(
    styles.button,
    styles[size],
    styles[theme],
    {
      [styles.withIcon]: icon && (label || children),
      [styles.reverse]: reverse,
      [styles.onlyIcon]: !children && !label,
      [styles.fullWidth]: fullWidth,
      [styles.isLoading]: loading,
      [styles.disabled]: disabled,
    },
    className
  );

  const cnIcon = cn(styles.icon, iconClassName, {
    [styles.disabled]: disabled,
  })

  const renderIcon = useMemo(
    () => icon && (
      <Icon
        label={icon}
        className={cnIcon}
        theme={iconTheme}
        active={iconActive}
        fill={iconFill}
      />
    ),
    [icon, iconActive, iconTheme, cnIcon, iconFill]
  );

  const buttonStyle = useMemo(
    () => ({
      ...rest.style,
      width: !width ? 'auto' : width,
      pointerEvents: disabled ? 'none' : 'initial'
    }),
    [disabled, width, rest.style]
  )

  const renderLoader = useMemo(
    () => loading && (
      <Loader
        className={styles.loader}
        theme={(theme === 'navy' || theme === 'ghost') ? 'navy' : 'light'}
        padding={0}
      />
    ),
    [theme, loading]
  );

  const hasLink = useMemo(
    () => to
      ? (
        <Link to={to}>
          {children}
        </Link>
      )
      : children,
    [children, to]
  );

  const button = useMemo(
    () => (
      <button
        {...rest}
        ref={ref}
        style={buttonStyle}
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={classNames}
      >
        {renderIcon}
        {renderLoader}
        <span className={styles.content}>
          {children && hasLink}
          {label && label}
        </span>
      </button>
    ),
    [disabled, children, label, onClick, type, ref, rest, classNames, renderIcon, renderLoader, hasLink, buttonStyle]
  )

  if (tooltip && disabled) {
    return (
      <Tooltip content={tooltip}>
        <span className={styles.spanWrapper}>
          {button}
        </span>
      </Tooltip>
    );
  }

  if (tooltip && !disabled) {
    return (
      <Tooltip content={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
});

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  size: PropTypes.oneOf(['huge', 'fat', 'big', 'medium', 'small']),
  theme: PropTypes.oneOf(['default', 'navy', 'ghost', 'icon', 'reset', 'light', 'blue']),
  iconTheme: PropTypes.oneOf(['solid', 'thin', 'regular']),
  icon: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  iconActive: PropTypes.bool,
  type: PropTypes.string,
  reverse: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  width: PropTypes.string,
  to: PropTypes.string,
  tooltip: PropTypes.string,
  iconFill: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  icon: null,
  label: null,
  size: 'medium',
  iconTheme: 'regular',
  iconActive: false,
  loading: false,
  fullWidth: false,
  theme: 'default',
  className: null,
  iconClassName: null,
  width: null,
  to: null,
  tooltip: null
};

export default memo(Button);
