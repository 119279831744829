import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './SelectNative.module.scss';

import Icon from '../Icon';

const SelectNative = ({
  id,
  className,
  onChange,
  disabled,
  error,
  helper,
  label,
  dataset,
  placeholder,
  required,
  touched,
  value,
  size,
  hidden,
  ...rest
}) => {
  const cnSelect = cn(styles.select, className, {
    [styles.noOptionSelected]: !value && placeholder,
    [styles.hasError]: touched && error && error.length > 0,
    [styles.disabled]: disabled
  });

  const cnControl = cn(styles.control, styles[size], {
    [styles.placeholder]: value === ''
  });

  const handleChange = useCallback(
    e => {
      onChange({ value: e.target.value })
    },
    [onChange]
  );

  return (
    <div className={cnSelect}>
      <label htmlFor={id}>
        <span className={styles.mask}>
          <select
            {...rest}
            id={id}
            onChange={handleChange}
            aria-required={required}
            className={cnControl}
            disabled={disabled}
            required={required}
            value={value}
          >
            {(placeholder || label) && (
              <option disabled hidden={hidden} value="">
                {(placeholder || label)}
              </option>
            )}
            {React.Children.toArray(dataset?.map(option => (
              <option
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            )))}
          </select>
          <Icon className={styles.arrow} label="chevron-down" width={12} />
        </span>
      </label>
      {helper && (
        <span className={cn(styles.message, styles.helperMessage)}>
          {helper}
        </span>
      )}
      {touched && error && (
        <span className={cn(styles.message, styles.errorMessage)}>{error}</span>
      )}
    </div>
  );
};

SelectNative.displayName = 'SelectNative';

SelectNative.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  error: PropTypes.string,
  helper: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
  value: PropTypes.string.isRequired
};

SelectNative.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: false,
  hidden: false,
  error: '',
  helper: '',
  label: '',
  placeholder: '',
  required: false,
  size: 'default',
  touched: true
};

export default SelectNative;
