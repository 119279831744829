/* eslint-disable no-return-assign */
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring/renderprops';
import cn from 'classnames';
import _differenceBy from 'lodash.differenceby';
import sortBy from 'lodash.sortby';
import deburr from 'lodash.deburr';

import Icon from '../Icon';
import Input from '../Input';
import PortalContainer from '../Portal/PortalContainer';
import SelectNative from '../SelectNative';
import Truncate from '../Truncate';
import isEventOutside from '../utils/isEventOutside';

import './Select.scss';

class Select extends PureComponent {
  mount = null;
  wait = null;
  timerReploy = null;
  listener = null;

  constructor(props) {
    super(props);
    this.state = {
      search: this.getItemFromValue(props.value).label || '',
      displayedSearch: this.getItemFromValue(props.value).label || '',
      deployed: false,
      displayFullDataset: true,
      renderOptions: false,
      activeResizeObserver: true,
      hasBeenFocused: false,
      focus: false,
      mount: false
    };
    this.ref = createRef();
    this.portal = createRef();
    this.input = createRef();
  }

  componentDidMount() {
    const { inPortal } = this.props;

    if (inPortal) {
      if (this.mount) clearTimeout(this.mount);
      this.mount = setTimeout(() => this.onMount(true), 250);
    }
    if (!inPortal) {
      this.onMount(true);
    }
  }

  onMount = mount => this.setState({ mount });

  onChangeDisplayedSearch = ({ value: displayedSearch }) => {
    this.setState({
      displayFullDataset: false,
      displayedSearch
    });
    if (this.props.hasSearch) this.setState({ search: displayedSearch });
  };

  onChangeSelectNative = ({ target: { value } }) => this.onChange(value);

  componentWillUnmount = () => {
    this.removeButtonListeners();
    this.closeMenuOptions(true);
    document.removeEventListener('click', this.onClickDocumentEvent);
    clearTimeout(this.wait);
    clearTimeout(this.timerReploy);
    clearTimeout(this.listener);
    clearTimeout(this.mount);
  };

  getItemFromValue = value => {
    const { dataset } = this.props;
    if (value === '') return ({ value: '', label: '' });
    const ob = dataset.find(d => d && (d.value || '')?.toString() === value?.toString());
    if (!ob) {
      console.error("[Select] Value", value, "not found in dataset"); // eslint-disable-line
      return {};
    }
    return ob;
  };

  getItemFromLabel = label => {
    const { dataset } = this.props;
    if (label === '') return null;
    return dataset?.find(d => typeof d?.label === 'string' && d?.label?.toLowerCase() === label?.toLowerCase()) || null;
  };

  componentDidUpdate = oldProps => {
    const { dataset, value } = this.props;
    const datasetDiff = _differenceBy(dataset, oldProps.dataset, 'value', 'label');

    if (oldProps.value !== value || datasetDiff.length > 0) {
      this.handleStateUpdate(this.getItemFromValue(value)?.label);
    }
  };

  handleStateUpdate = data => {
    this.setState({
      search: data,
      displayedSearch: data
    })
  };

  onClickChange = value => () => this.onChange(value);

  onChange = value => {
    const { onChange, removeSearchOnClick, closeOnChange, isMultiple } = this.props;

    if (closeOnChange) this.closeMenuOptions();
    const ob = this.getItemFromValue(value);
    if (!isMultiple) this.setState({ search: removeSearchOnClick ? '' : ob.label, displayedSearch: removeSearchOnClick ? '' : ob.label });
    onChange({ type: 'select', ...ob });
    return value;
  };

  onFocusSearch = () => {
    const { onFocus } = this.props;
    const { hasBeenFocused } = this.state;
    this.setState({ focus: true });
    if (!hasBeenFocused) this.setState({ hasBeenFocused: true });
    this.setState({ displayFullDataset: true }, () => {
      this.openMenuOptions();
    });
    if (onFocus) onFocus();
  };

  onBlurSearch = () => {
    const { onBlur, closeOnBlur } = this.props;
    const { deployed } = this.state;

    if (closeOnBlur && deployed) this.wait = setTimeout(this.closeMenuOptions, 150);
    if (onBlur) onBlur();
  };

  openMenuOptions = async () => {
    const { value } = this.props;
    const { deployed } = this.state;

    if (deployed) return null;

    const displayedDataset = this.getDisplayDataset();

    if (this.props.useNative) return null;

    await this.setState({ deployed: true, renderOptions: true });

    if (this.timerReploy) clearTimeout(this.timerReploy);

    if (!this.dom) this.dom = this.ref.current;

    if (value && value !== '') {
      const index = displayedDataset.findIndex(search => search.value === value);
      const list = this.selectOptionList;
      if (!list) return;
      const selected = list.getElementsByTagName('li')[index];
      if (selected?.offsetTop + selected?.clientHeight > list.clientHeight) list.scrollTop = selected?.clientHeight * index;
    }

    if (this.listener) clearTimeout(this.listener);
    this.listener = setTimeout(
      () => document.addEventListener('click', this.onClickDocumentEvent),
      300
    );
    this.setState({ activeResizeObserver: true });
  };

  closeMenuOptions = (instant = false) => {
    const { useNative } = this.props;
    const { deployed } = this.state;

    this.setState({ focus: false });

    if (!deployed || useNative) return null;

    this.setState({ deployed: false });

    if (instant) this.setState({ renderOptions: false });

    document.removeEventListener('click', this.onClickDocumentEvent);

    this.removeButtonListeners();

    if (this.timerReploy) clearTimeout(this.timerReploy);

    this.timerReploy = setTimeout(() => {
      this.setState({
        renderOptions: false,
        activeResizeObserver: false
      });
    }, 200);
  };

  addButtonListener = () => {
    window.addEventListener('keydown', this.onDocumentKeyPressed);
  };

  removeButtonListeners = () => {
    window.removeEventListener('keydown', this.onDocumentKeyPressed);
  };

  onDocumentKeyPressed = e => {
    e.preventDefault();
    this.onKeyPress({
      key: e.key,
      keyCode: e.keyCode,
      event: e
    });
  };

  onClickButton = () => {
    const { deployed } = this.state;

    this.setState({
      hasBeenFocused: true,
      focus: true
    });

    if (deployed) this.closeMenuOptions();
    else {
      this.openMenuOptions();
      this.addButtonListener()
    }
  };

  onKeyPress = event => {
    const { displayedSearch, deployed } = this.state;
    const { value, onFocusOut, onTabPressed } = this.props;
    const { input } = this;
    const displayedDataset = this.getDisplayDataset();
    const index = displayedDataset.findIndex(search => search.label === displayedSearch);

    switch (event.key) {
      case 'Tab': {
        const hasBeenSelected = this.onEnterPress();
        if (onTabPressed) onTabPressed({event, value: hasBeenSelected});
        break;
      }
      case 'Escape': {
        const { label } = this.getItemFromValue(value);
        this.setState({ search: label, displayedSearch: label });
        if (onFocusOut) onFocusOut();
        this.closeMenuOptions();
        if (displayedSearch === '') { this.onChange(''); }
        break;
      }
      case 'ArrowDown': {
        const selectDown = index > -1 && index < displayedDataset.length - 1 ? index + 1 : 0;
        this.setState({displayedSearch: displayedDataset[selectDown].label})
        this.openMenuOptions();
        if (deployed) {
          const list = this.selectOptionList;
          if (!list) return;
          const selected = list.getElementsByTagName('li')[selectDown];
          if (selectDown === 0) {
            list.scrollTop = 0
          }
          // if the selected item is off screen, scroll down
          if (selected.offsetTop + selected.clientHeight >= list.clientHeight + list.scrollTop) { list.scrollTop += selected.clientHeight; }
        }
        break;
      }
      case 'ArrowUp': {
        this.openMenuOptions();
        event.preventDefault(); // set caret position to the end
        if (input && input.value) input.setSelectionRange(input.value?.length, input.value?.length);
        const selectUp = index > 0 ? index - 1 : displayedDataset.length - 1;
        this.setState({displayedSearch: displayedDataset[selectUp].label})
        if (deployed) {
          const list = this.selectOptionList;
          if (!list) return;
          const selected = list.getElementsByTagName('li')[selectUp];
          if (selectUp === displayedDataset.length - 1) return list.scrollTop = selected.clientHeight * displayedDataset.length;
          // if the selected item is off screen, scroll up
          else if (selected.offsetTop < list.scrollTop) { list.scrollTop -= selected.clientHeight; }
        }
        break;
      }
      case 'Enter':
        this.onEnterPress();
        break;
      default:
        this.openMenuOptions();
    }
  };

  onClickDocumentEvent = e => {
    e.preventDefault();
    const { value, onFocusOut } = this.props;
    // Click outside component
    if (isEventOutside(e, this.portal.current)) {
      // We remove search and reinitialize displayed value
      this.setState({
        search: '',
        displayedSearch: this.getItemFromValue(value).label
      }, () => this.closeMenuOptions());
      if (onFocusOut) onFocusOut();
    }
  };

  getDisplayDataset = () => {
    const { dataset, pinned, maxListItems, useNative, sort, selected, hasSearch } = this.props;
    const { displayedSearch, search, displayFullDataset } = this.state;

    const selectedItems = dataset.map(d => ({...d, selected: displayedSearch === d.label || selected.findIndex(p => p === d.value) > -1}));
    const sorted = sort ? sortBy(selectedItems, [({label}) => deburr(label)]) : selectedItems;
    const withPins = [...pinned.map(pin => ({pinned: true, ...sorted.find(d => d.value === pin)})), ...sorted.filter(s => pinned.findIndex(p => p === s.value) === -1)];
    if (displayFullDataset) return withPins;
    const filtered = !useNative && hasSearch ? withPins.filter(v => deburr(v.label.toLowerCase()).indexOf(deburr(search.toLowerCase())) > -1) : withPins;
    return filtered.slice(0, (maxListItems > -1 && (!useNative && hasSearch)) ? maxListItems : filtered.length);
  };

  onEnterPress = () => {
    const { displayedSearch, search } = this.state;

    this.setState({ search: displayedSearch });
    const ob = this.getItemFromLabel(displayedSearch);
    // Label matches an item > we select it
    if (ob) return this.onChange(ob.value);

    if (search === '') {
      this.setState({search: '', displayedSearch: ''});
      return this.onChange('');
    }
    // we select first item in datalist
    const ds = this.getDisplayDataset();
    if (ds.length > 0) return this.onChange(ds[0].value);
    // else, we remove the search
    this.setState({ search: '', displayedSearch: '' });
    return '';
  };

  renderNative = () => {
    const { value, label, labelAsPlaceholder, placeholder, disabled, onChange, size } = this.props;

    const dataset = this.getDisplayDataset();

    return (
      <SelectNative
        value={value}
        placeholder={labelAsPlaceholder ? label : placeholder}
        dataset={dataset}
        onChange={onChange}
        disabled={disabled}
        onFocus={this.onFocusSearch}
        onBlur={this.onBlurSearch}
        size={size}
      />
    )
  }

  renderInput = () => {
    const { displayedSearch, deployed, focus } = this.state;
    const {
      required,
      defaultOptionLabel,
      value,
      error,
      controlledError,
      shrink,
      label,
      labelAsPlaceholder,
      placeholderOnFocus,
      withMask,
      disabled,
      size,
      ...rest
    } = this.props;
    let placeholder = defaultOptionLabel;

    if (focus) {
      placeholder = placeholderOnFocus;
    } else if (labelAsPlaceholder) {
      placeholder = label;
    }

    return (
      <>
        <Input
          {...rest}
          ref={node => this.input = node}
          value={(shrink || labelAsPlaceholder) ? displayedSearch : ''}
          onChange={this.onChangeDisplayedSearch}
          onKeyPress={this.onKeyPress}
          required={required}
          label={labelAsPlaceholder ? '' : label}
          onFocus={this.onFocusSearch}
          placeholder={labelAsPlaceholder ? label : placeholder}
          onBlur={this.onBlurSearch}
          size={size}
          fullWidth
          autoComplete="off"
        />
        {withMask && !deployed && !disabled && (
          <div className="bnc_field_select_inputMask" onClick={this.onFocusSearch} />
        )}
      </>
    )
  };

  renderButton = () => {
    const { displayedSearch, hasBeenFocused, focus } = this.state;
    const { label, defaultOptionLabel, required, disabled, error, value, shrink } = this.props;

    const hasError = error || (hasBeenFocused && required && (!value || value === '') && !focus);

    return (
      <label className={cn('bnc_field_button_as_input', {
        'bnc_field_select_button--shrink-label': (focus || defaultOptionLabel !== '' || (value && value !== '')) && shrink,
        'bnc_field_select_button--has-label': label && label !== '',
        'bnc_field_select_button--disabled': disabled,
        'bnc_field_select_button--error': hasError
      })}>
        {label && label !== '' && <span className="label">{label}{required && (<abbr>*</abbr>)}</span>}
        <button
          onClick={this.onClickButton}
          disabled={disabled}
        >
          <Truncate>
            {shrink && displayedSearch && displayedSearch !== '' ? displayedSearch : (defaultOptionLabel || '')}
          </Truncate>
        </button>
      </label>
    )
  };

  renderEnhanced = () => {
    const { deployed, renderOptions, activeResizeObserver } = this.state;
    const { hasSearch, inPortal } = this.props;

    const cnIcon = cn('selectIcon', {
      'icon--rotate--180': deployed
    });

    const cnMask = cn('bnc_field_select_options_mask', {
      'deployed': deployed,
      'rendered': renderOptions
    });

    const selectOptionsComponent = (
      <div className="selectOptions" ref={this.portal}>
        <div className={cnMask}>
          <div className="bnc_field_select_options_list" ref={node => this.selectOptionList = node}>
            {renderOptions && this.renderEnchancedOptions()}
          </div>
        </div>
      </div>
    );

    return (
      <PortalContainer disablePortal={!inPortal} html={selectOptionsComponent} on={activeResizeObserver}>
        <div className="bnc_field_select_enhanced">
          {hasSearch ? this.renderInput() : this.renderButton()}
          <Icon
            label="chevron-down"
            className={cnIcon}
            width={12}
          />
        </div>
      </PortalContainer>
    );
  };

  renderEnchancedOptions = () => {
    const { noMatchPlaceholder } = this.props;
    const dataset = this.getDisplayDataset();

    if (!dataset.length) {
      return noMatchPlaceholder
        ? (
          <div className="bnc_field_select_options_list_empty">
            {noMatchPlaceholder}
          </div>
        ) : null
    }
    return (
      <ul>
        {React.Children.toArray(
          dataset?.map(({ value: ov, label: ol, react, disabled, icon, pinned, selected }, optionIndex) => (
            <li
              key={`${ov}-${optionIndex}`}
              value={ov}
              className={cn({ pinned, selected, disabled })}
              role="option"
              aria-selected={selected || false}
              onClick={disabled ? null : this.onClickChange(ov)}
            >
              {react ||
              <Truncate>
                {icon} {ol}
              </Truncate>}
            </li>
          )))}
      </ul>
    );
  }

  render = () => {
    const { useNative, className, required, errorRequiredText, value, disabled, size, labelAsOption, activeLabel } = this.props;
    const { hasBeenFocused, focus, mount } = this.state;

    const cnSelect = cn('selectComponent', className, `bnc_field_select--size--${size}`, {
      'bnc_field_select--disabled': disabled,
      'bnc_field_select--label-as-option': labelAsOption,
      'bnc_field_select--active-placeholder': activeLabel
    });

    return mount && (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: mount && 1 }}>
        {props => (
          <div ref={this.ref} className={cnSelect} style={props}>
            {useNative ? this.renderNative() : this.renderEnhanced()}
            {hasBeenFocused && !focus && required && (!value || value === '') && errorRequiredText && errorRequiredText !== '' && <span className="error">{errorRequiredText}</span>}
          </div>
        )}
      </Spring>
    )
  }
}

Select.displayName = 'Select';

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hasSearch: PropTypes.bool,
  dataset: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    react: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.element,
    selected: PropTypes.bool,
    disabled: PropTypes.bool
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  pinned: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  useNative: PropTypes.bool,
  maxListItems: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errorRequiredText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultOptionLabel: PropTypes.string,
  className: PropTypes.string,
  sort: PropTypes.bool,
  removeSearchOnClick: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  controlledError: PropTypes.bool,
  error: PropTypes.bool,
  onTabPressed: PropTypes.func,
  size: PropTypes.string,
  closeOnChange: PropTypes.bool,
  onFocusOut: PropTypes.func,
  shrink: PropTypes.bool,
  labelAsOption: PropTypes.bool,
  labelAsPlaceholder: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  isMultiple: PropTypes.bool,
  multiSearch: PropTypes.func,
  placeholderOnFocus: PropTypes.string,
  noMatchPlaceholder: PropTypes.string,
  inPortal: PropTypes.bool,
  activeLabel: PropTypes.bool,
  withMask: PropTypes.bool
};

Select.defaultProps = {
  hasSearch: true,
  useNative: false,
  maxListItems: -1,
  disabled: false,
  required: false,
  errorRequiredText: ``,
  label: ``,
  placeholder: ``,
  defaultOptionLabel: '',
  className: ``,
  sort: true,
  pinned: [],
  selected: [],
  removeSearchOnClick: false,
  onBlur: null,
  onFocus: null,
  controlledError: false,
  error: false,
  onTabPressed: null,
  size: 'default',
  closeOnChange: true,
  onFocusOut: null,
  shrink: true,
  labelAsOption: false,
  labelAsPlaceholder: false,
  closeOnBlur: true,
  isMultiple: false,
  multiSearch: null,
  placeholderOnFocus: '',
  noMatchPlaceholder: null,
  inPortal: false,
  activeLabel: false,
  withMask: true
};

export default Select;
