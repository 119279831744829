import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import InputSearchLocation from './InputSearchLocation';
import { StringOrReact } from '../utils/propTypes';

const InputGmap = ({ onChange, name, placeholder, value, error, disabled, size, gmapApiKey, className, ...rest }) => {
  const handleChange = useCallback(
    ({ placeSearched, mapped }) => {
      if (!onChange) return null;
      onChange({
        name,
        placeSearched,
        mapped
      });
    },
    [onChange, name]
  );

  return (
    <InputSearchLocation
      {...rest}
      name={name}
      className={className}
      placeholder={placeholder}
      value={value}
      error={error}
      onChange={handleChange}
      disabled={disabled}
      size={size}
      gmapApiKey={gmapApiKey}
    />
  )
};

InputGmap.displayName = 'InputGmap';

InputGmap.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  label: StringOrReact,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf([
    'huge',
    'big',
    'medium',
    'default',
    'small'
  ]),
  gmapApiKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

InputGmap.defaultProps = {
  value: '',
  name: '',
  className: null,
  label: '',
  placeholder: '',
  disabled: false
};

export default InputGmap;
