/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from 'react';
import useDebounce from './useDebounce';

const useSearch = ({ data = [], key, debounceDelay = 200 }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [result, setResult] = useState(() => data);
  const debouncedValue = useDebounce(searchQuery, debounceDelay);

  const handleChange = useCallback(
    ({ value }) => setSearchQuery(value),
    [setSearchQuery]
  );

  useEffect(() => {
    !debouncedValue
      ? setResult(data)
      : setResult(data?.filter(value => {
        const target = !key ? value : value[key];
        return target?.toLowerCase().includes(debouncedValue?.toLowerCase())
      }));
  }, [debouncedValue, data, setResult, key]);

  return {
    searchQuery,
    handleChange,
    result
  }
};

export default useSearch;
