import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './QualityScore.module.scss';

const QualityScore = ({ radiusSize, qualityScore, className, displayWording, ...rest }) => {
  const [dataScore, setDataScore] = useState(qualityScore);

  useEffect(() => {
    setDataScore(parseInt(qualityScore, 10));
  }, [qualityScore]);

  const radius = radiusSize / 2;
  const sizeSvg = radiusSize + 2;
  const center = sizeSvg / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference * (1 - dataScore / 100);

  const style = useMemo(
    () => ({
      width: radiusSize,
      height: radiusSize
    }),
    [radiusSize]
  );

  const spanStyle = useMemo(
    () => ({
      fontSize: radiusSize > 50
        ? 48 * radius / 100
        : 15
    }),
    [radiusSize, radius]
  );

  const cnContainer = cn(styles.qualityScore, className);

  const cnCircle = cn(styles.circle, {
    [styles.green]: dataScore >= 85,
    [styles.lightGreen]: dataScore >= 70 && dataScore < 85,
    [styles.yellow]: dataScore >= 60 && dataScore < 70,
    [styles.orange]: dataScore >= 50 && dataScore < 60,
    [styles.red]: dataScore < 50
  });

  return (
    <div className={cnContainer} style={style} {...rest}>
      <span className={styles.qualityScoreInner} style={spanStyle}>
        {qualityScore !== null ? `${Math.floor(qualityScore)}` : '-'}
      </span>
      <svg className={styles.round} width={sizeSvg} height={sizeSvg}>
        <circle cx={center} cy={center} r={radius} fill="none" stroke="#DDDDDD" strokeWidth="2" />
        <circle
          className={cnCircle}
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  )
};

QualityScore.displayName = 'QualityScore';

QualityScore.propTypes = {
  radiusSize: PropTypes.number,
  qualityScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string,
  displayWording: PropTypes.bool
};

QualityScore.defaultProps = {
  radiusSize: 35,
  qualityScore: null,
  className: null,
  displayWording: true
}

export default memo(QualityScore);
