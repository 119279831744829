import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './Modal.module.scss';

const Footer = ({ action, ...rest }) => {
  const { primary, secondary } = action;

  const displayPrimaryButton = useMemo(
    () => primary && primary.title && (
      <Button
        onClick={primary.onClick}
        disabled={primary.disabled}
        tooltip={primary.tooltip}
      >
        {primary.title}
      </Button>
    ),
    [primary]
  );

  const displaySecondaryButton = useMemo(
    () => secondary && secondary.title && (
      <Button
        theme="ghost"
        onClick={secondary.onClick}
        disabled={secondary.disabled}
        tooltip={secondary.tooltip}
      >
        {secondary.title}
      </Button>
    ),
    [secondary]
  );

  return (
    <div className={styles.footer} {...rest}>
      {displaySecondaryButton}
      {displayPrimaryButton}
    </div>
  )
};

Footer.propTypes = {
  action: PropTypes.shape({
    primary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    }),
    secondary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    })
  })
}

Footer.defaultProps = {
  action: {
    primary: {
      onClick: null,
      title: null,
      disabled: false
    },
    secondary: {
      onClick: null,
      title: null,
      disabled: false
    }
  }
}

export default memo(Footer);
