import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './StepWrapper.module.scss';

const StepWrapper = props => {
  const { children, className, step, totalStep, width } = props;

  const cnContainer = cn(styles.container, className);

  const styleContainer = useMemo(
    () => ({ width }),
    [width]
  );

  const styleLoader = useMemo(
    () => ({ width: `${(step * 100) / totalStep}%` }),
    [step, totalStep]
  );

  return (
    <div {...props} className={cnContainer} style={styleContainer}>
      <div className={styles.loader}>
        <span className={styles.bar} style={styleLoader} />
      </div>
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  )
};

StepWrapper.displayName = 'StepWrapper';

StepWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  step: PropTypes.number.isRequired,
  totalStep: PropTypes.number.isRequired,
  width: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number
  ])
};

StepWrapper.defaultProps = {
  className: null,
  width: 620
}

export default memo(StepWrapper);
