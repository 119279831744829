import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { animated, useTransition } from 'react-spring';

import styles from './Overlay.module.scss';

const Overlay = forwardRef(({ children, on, className, theme, onClick, ...rest }, ref) => {
  const transitions = useTransition(on, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      mass: 1,
      tension: 320,
      friction: 28
    }
  });

  const cnOverlay = cn(styles.overlay, className);

  return transitions.map(({ item, key, props }) => (
    item ? (
      <animated.div
        {...rest}
        key={key}
        ref={ref}
        style={props}
        className={cnOverlay}
      >
        {children}

      ️</animated.div>
    ): null
  ))
});

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'transparent']),
  on: PropTypes.bool.isRequired
};

Overlay.defaultProps = {
  className: undefined,
  theme: 'default',
  onClick: null
};

export default memo(Overlay);
