import React, {forwardRef, memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmail from 'validator/lib/isEmail';

import Input from '../Input'
import InputGmap from '../InputGmap';

import styles from './Field.module.scss';

const types = ['text', 'phone', 'mail', 'location', 'date', 'dateRange'];

const Field = forwardRef((props, ref) => {
  const { name, value, placeholder, onChange, className, fieldType, error, ...rest } = props;

  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (error) setError(error)
  }, [error])

  const handleChange = useCallback(
    ({ value, placeSearched, mapped, id, startDate, endDate }) => {
      if (!onChange) return;
      if (fieldType === 'mail') {
        setError(value !== '' && !isEmail(value));
      }
      onChange({
        id,
        name,
        value: fieldType === 'phone' ? value.replace(/\D+/g, '') : value,
        placeSearched,
        startDate,
        endDate,
        mapped,
        error
      });
    },
    [onChange, name, fieldType, error]
  );

  const cnInput = cn(styles.input, className);

  /* return types.map(field => {
    switch (field) {
      case 'text': return (
        <Input
          {...rest}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className={cnInput}
          error={hasError}
          fullWidth
        />
      );
      case 'location': return (
        <InputGmap
          {...rest}
          ref={ref}
          name={name}
          className={cnInput}
          placeholder={placeholder}
          value={value}
          error={hasError}
          onChange={handleChange}
        />
      )
      default: return null;
    }
  }); */

  return fieldType !== 'location' ? (
    <Input
      {...rest}
      ref={ref}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className={cnInput}
      error={hasError}
      fullWidth
    />
  ) : (
    <InputGmap
      {...rest}
      ref={ref}
      name={name}
      className={cnInput}
      placeholder={placeholder}
      value={value}
      error={hasError}
      onChange={handleChange}
    />
  );
});

Field.displayName = 'Field';

Field.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  fieldType: PropTypes.oneOf(types)
};

Field.defaultProps = {
  className: undefined,
  value: undefined,
  placeholder: undefined,
  onChange: undefined,
  error: false,
  fieldType: 'text'
};

export default memo(Field);
