/* eslint-disable react/prop-types */
import React, { Children, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from './BarChart2.module.scss';

const style = (percent, bg) => ({
  width: `${percent}%`,
  background: bg,
  height: '100%'
});

const initTooltip = item => {
  const bg = { backgroundColor: item.color };

  const dot = <span className={styles.dot} style={bg} />;

  const cnLabel = cn(styles.label, { [styles.highlight]: item.highlight });

  const tooltip = (
    <div className={styles.item}>
      {dot}
      <span className={cnLabel}>{item.label}</span>
      <span className={styles.value}>{item.value}</span>
      <span className={styles.value}>{item.percent}%</span>
    </div>
  );

  return {
    dot,
    tooltip
  };
};

const BarChart2 = ({ data, total, legend, className, ...rest }) => {
  const renderBar = useMemo(
    () =>
      Children.toArray(
        data.map(item => (
          <div
            className={styles.segment}
            style={style(item.percent, item.color)}
          />
        ))
      ),
    [data]
  );

  const renderLabel = useMemo(
    () =>
      legend ? (
        <div className={styles.labelWrapper}>
          {Children.toArray(
            data.map((item, index) => {
              const cnLabelText = cn(styles.labelText, {
                [styles.highlight]: item.highlight
              });
              return index < 3 ? (
                <div className={styles.label}>
                  {initTooltip(item).dot}
                  <span className={cnLabelText}>{item.label}</span>
                  {index === 2 && data.length > 3 && (
                    <span style={{ color: '#1E5C90' }}>
                      {`+${data.length - 3}`}
                    </span>
                  )}
                </div>
              ) : null;
            })
          )}
        </div>
      ) : null,
    [data, legend]
  );

  const tooltipContent = useMemo(() => {
    return (
      <div className={styles.tooltip}>
        <div className={styles.item}>
          <span />
          <span className={styles.label}>Total</span>
          <span className={styles.value}>{total}</span>
          <span className={styles.value}>100%</span>
        </div>
        {React.Children.toArray(data.map(item => initTooltip(item).tooltip))}
      </div>
    );
  }, [data, total]);

  const cnBarChart = cn(styles.container, className);

  return (
    <div className={cnBarChart} {...rest}>
      <Tooltip content={tooltipContent}>
        <div className={styles.wrapper}>
          {renderLabel}
          <div className={styles.bar}>{renderBar}</div>
        </div>
      </Tooltip>
    </div>
  );
};

BarChart2.displayName = 'BarChart2';

BarChart2.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.number,
      color: PropTypes.string,
      value: PropTypes.string,
      highlight: PropTypes.bool
    })
  ).isRequired,
  total: PropTypes.string.isRequired,
  legend: PropTypes.bool,
  className: PropTypes.string
};

BarChart2.defaultProps = {
  legend: true,
  className: null
};

export default memo(BarChart2);
