/* eslint-disable no-undef */
import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './LazyImage.module.scss';

const placeholder = '...';

const LazyImage = ({ src, alt, className, ...rest }) => {
  const [imageSrc, setImageSrc] = useState(placeholder);
  const [imageRef, setImageRef] = useState();

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef)
              }
              setImageSrc(src);
            })
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        );
        observer.observe(imageRef)
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  }, [src, imageSrc, imageRef]);

  const cnImg = cn(styles.img, className);
  const onLoad = e => e.target.classList.add('loaded');
  const onError = e => e.target.classList.add('hasError');

  return (
    <img
      {...rest}
      className={cnImg}
      ref={setImageRef}
      src={imageSrc}
      alt={alt}
      onLoad={onLoad}
      onError={onError}
    />
  )
};

export default memo(LazyImage);
