import React from 'react';
import { ReactComponent as Gmail } from './gmail.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as InstaStory } from './insta-stories.svg';
import { ReactComponent as InstaVideo } from './insta-video.svg';
import { ReactComponent as Facebook } from './facebook-square.svg';
import { ReactComponent as Kolsquare } from './kolsquare.svg';
import { ReactComponent as Tiktok } from './tiktok.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Youtube } from './youtube.svg';

export default () => ({
  'gmail': <Gmail />,
  'google': <Google />,
  'instagram': <Instagram />,
  'instagram-color': <Instagram />,
  'instagram-story': <InstaStory />,
  'instagram-video': <InstaVideo />,
  'facebook': <Facebook />,
  'facebook-color': <Facebook />,
  'kolsquare': <Kolsquare />,
  'tiktok': <Tiktok />,
  'tiktok-color': <Tiktok />,
  'twitter': <Twitter />,
  'twitter-color': <Twitter />,
  'youtube': <Youtube />,
  'youtube-color': <Youtube />,
  undefined: <Instagram />
});
