import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon';
import formatNumber from '../utils/formatNumber';

import styles from './PieChart.module.scss';

const PieChart = ({ data, size, className, locale, toFixed, precision, ...rest }) => {
  const style = useMemo(
    () => ({
      width: size,
      height: size,
      transform: 'rotate(-90deg)'
    }),
    [size]
  );

  const renderPie = useCallback(
    percent => {
      const x = Math.cos(2 * Math.PI * percent);
      const y = Math.sin(2 * Math.PI * percent);
      const largeArcFlag = percent > 0.5 ? 1 : 0;

      return (
        <svg
          className={styles.pieChart}
          viewBox="-1 -1 2 2"
          style={style}
        >
          <circle cx={0} cy={0} r={1} fill="#DCCCFF" />
          <path fill="#00CCFF" d={`
            M 1 0
            A 1 1 0 ${largeArcFlag} 1 ${x} ${y}
            L 0 0
          `}
          />
        </svg>
      )
    },
    [style]
  );

  const cnContainer = cn(styles.container, className);

  const cnDetails = cn(styles.details, {
    [styles.smallDetails]: size < 80
  });

  const { male, female } = data;

  return (
    <div className={cnContainer} {...rest}>
      {renderPie(male / 100)}
      <div className={cnDetails}>
        <div>
          <Icon className={styles.icon} width={28} label="venus" fill="#DCCCFF" />
          {formatNumber(female, locale, toFixed, precision)} %
        </div>

        <div>
          <Icon className={styles.icon} width={28} label="mars" fill="#00CCFF" />
          {formatNumber(male, locale, toFixed, precision)} %
        </div>
      </div>
    </div>
  );
};

PieChart.displayName = 'PieChart';

PieChart.propTypes = {
  data: PropTypes.shape({
    male: PropTypes.number,
    female: PropTypes.number
  }).isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  locale: PropTypes.string,
  toFixed: PropTypes.number,
  precision: PropTypes.number
};

PieChart.defaultProps = {
  size: 120,
  className: null,
  locale: 'fr-FR',
  toFixed: 1,
  precision: 3
}

export default memo(PieChart);
