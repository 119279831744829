import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

import styles from './Chips.module.scss';

const Chips = forwardRef(({ counter, label, className, onClick, theme, size, status, maxWidth, id, ...rest }, ref) => {
  const cnChips = cn(
    styles.chips,
    styles[theme],
    styles[size],
    styles[status],
    className
  );

  const cnSpan = cn(
    styles.span,
    styles[theme],
    styles[size],
    styles[status], {
      [styles.maxWidth]: maxWidth
    }
  );

  const handleClick = useCallback(
    (id, value ) => onClick({ id, value }),
    [onClick]
  )

  const styleSpan = useMemo(
    () => ({ maxWidth: !maxWidth ? 'auto' : maxWidth }),
    [maxWidth]
  );

  const displayChips = () => (
    <div className={cnChips} ref={ref} {...rest}>
      <div className={cnSpan} style={styleSpan}>{label}</div>
      {onClick && (
        <Icon
          className={styles.close}
          label="times"
          width={10}
          onClick={handleClick}
        />
      )}
      {counter && (
        <span className={styles.counter}>
          {counter}
        </span>
      )}
    </div>
  );

  return !maxWidth
    ? displayChips()
    : (
      <Tooltip content={label}>
        {displayChips()}
      </Tooltip>
    );
});

Chips.displayName = 'Chips';

Chips.propTypes = {
  counter: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['basic', 'hashtag', 'category', 'info', 'label', 'labelLight', 'status']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  status: PropTypes.oneOf(['draft', 'ready', 'current', 'end', 'archived']),
  maxWidth: PropTypes.string
};

Chips.defaultProps = {
  counter: null,
  className: undefined,
  onClick: null,
  theme: 'default',
  size: 'small',
  maxWidth: null
};

export default memo(Chips);
