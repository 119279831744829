import React, { Children, memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Wrapper.module.scss';

const Wrapper = ({ children }) => {
  return (
    <div className={styles.container}>
      {Children.map(children, child => (
        <div className={styles.wrapper}>
          {child}
        </div>
      ))}
    </div>
  )
};

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = {
  children: PropTypes.element.isRequired
};

Wrapper.defaultProps = {
  title: null
};

export default memo(Wrapper);
