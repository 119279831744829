import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../Icon';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  checked,
  className,
  disabled,
  id,
  itemId,
  label,
  name,
  onChange,
  required,
  halfchecked,
  fullWidth,
  labelClassName,
  theme,
  ...rest
}) => {
  const cnInputCheck = cn(styles.inputCheck, styles[theme], className, {
    [styles.fullWidth]: fullWidth,
    [styles.required]: required,
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, styles[theme], labelClassName, {
    [styles.disabled]: disabled
  });

  return (
    <div className={cnInputCheck}>
      <input
        {...rest}
        type="checkbox"
        aria-describedby={label}
        checked={checked}
        data-id={itemId}
        disabled={disabled}
        id={String(id)}
        name={name}
        onChange={onChange}
        required={required}
      />
      <label className={styles.customInput} htmlFor={id}>
        {(checked || halfchecked) && (
          <Icon
            label={!halfchecked ? 'check' : 'minus'}
            theme="solid"
            className={styles.icon}
            width={10}
            fill="#fff"
          />
        )}
      </label>
      {label &&
        <label htmlFor={id} className={cnLabel}>
          {label}
        </label>
      }
    </div>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  halfchecked: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  itemId: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: false,
  halfchecked: false,
  className: undefined,
  labelClassName: undefined,
  disabled: false,
  fullWidth: false,
  itemId: '',
  label: null,
  required: false,
  onChange: () => {}
};

export default memo(Checkbox);
