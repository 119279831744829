import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Divider.module.scss';

const Divider = ({ className, size, ...rest }) => {
  const cnDivider = cn(styles.divider, styles[size], className)
  return (
    <div className={cnDivider} {...rest} />
  );
};

Divider.displayName = 'Divider';

Divider.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default'])
};

Divider.defaultProps = {
  className: null,
  size: 'default'
}

export default memo(Divider);
