import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Switch2.module.scss';

const Switch2 = ({
  label,
  name,
  id,
  checked,
  onChange,
  className,
  itemId,
  disabled
}) => {
  const cnSwitch = cn(styles.container, className, {
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, {
    [styles.active]: checked,
    [styles.disabled]: disabled
  });

  const cnLabelWrapper = cn(styles.labelWrapper, {
    [styles.disabled]: disabled
  })

  return (
    <div className={cnSwitch}>
      <label htmlFor={id} className={cnLabel}>
        <input
          className={styles.input}
          type="checkbox"
          id={id}
          name={name}
          value={id}
          data-itemid={itemId}
          checked={checked}
          onChange={onChange}
          aria-checked={checked}
          aria-describedby={id}
          disabled={disabled}
        />
      </label>
      <div className={cnLabelWrapper} onClick={!disabled && onChange}>
        <span>{label}</span>
      </div>
    </div>
  );
};

Switch2.displayName = 'Switch2';

Switch2.propTypes = {
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

Switch2.defaultProps = {
  itemId: '',
  name: null,
  className: '',
  onChange: () => {},
  disabled: false
};

export default memo(Switch2);
