import React, { Children, memo } from 'react';
import PropTypes from 'prop-types';

import BigRadio from './BigRadio';
import styles from './BigRadio.module.scss';

const BigRadios = props => {
  const { className, name, onChange, options, required, checked } = props;

  return (
    <div className={className}>
      {options && (
        Children.toArray(options.map((option, index) => {
          const value = option.value ? option.value : option.label;
          return React.cloneElement(
            <BigRadio
              className={styles.radio}
              name={name}
              id={`${name}-${index}`}
              label={option.label}
              value={value}
              checked={checked === value}
              disabled={option.disabled}
              onChange={onChange}
              required={required}
            />
          );
        }))
      )}
    </div>
  );
};

BigRadios.displayName = 'BigRadios';

BigRadios.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  required: PropTypes.bool,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string
    )
  ])
};

BigRadios.defaultProps = {
  className: '',
  required: false,
  checked: '',
  touched: true,
  onChange: null
};

export default memo(BigRadios);
