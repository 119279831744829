import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';
import Text from '../Text';
import TruncateText from '../TruncateText';

import styles from './Item.module.scss';

const uniqid = require('uniqid');

const Item = forwardRef(({
  tag,
  item,
  icon,
  iconTheme,
  iconFill,
  children,
  className,
  onClick,
  react,
  active,
  disabled,
  ...rest
 }, ref) => {
  const cnSpan = cn(styles.span, className, {
    [styles.button]: onClick,
    [styles.active]: active,
    [styles.disabled]: disabled,
  });

  const handleClick = useCallback(
    () => !disabled ? onClick(item) : null,
    [disabled, onClick, item]
  );

  const renderReact = useMemo(
    () => react && React.cloneElement(
      react, {
        ...rest,
        key: uniqid(),
        className: styles.react
      }
    ),
    [react, rest]
  );

  const renderIcon = useMemo(
    () => icon ? (
      <Icon
        key={icon}
        label={icon}
        theme={iconTheme}
        fill={iconFill}
        width={16}
        className={styles.icon}
      />
    ) : null,
    [icon, iconTheme, iconFill]
  );

  return (
    <Text
      tag={tag}
      ref={ref}
      className={cnSpan}
      onClick={handleClick}
      aria-selected={active}
      role="option"
      resetMargin
      {...rest}
    >
      {renderIcon}
      {renderReact}
      <TruncateText>
        {children}
      </TruncateText>
    </Text>
  )
});

Item.displayName = 'Span';

Item.propTypes = {
  tag: PropTypes.any,
  item: PropTypes.any,
  icon: PropTypes.string,
  iconTheme: PropTypes.string,
  iconFill: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  react: PropTypes.element,
};

Item.defaultProps = {
  tag: 'span',
  item: null,
  icon: null,
  iconFill: '#003057',
  iconTheme: 'thin',
  children: null,
  className: null,
  onClick: () => {},
  disabled: false,
  fullWidth: false,
  react: null,
}

export default memo(Item);
