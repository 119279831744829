import React, { Children, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Radio from '../Radio';
import styles from './Radios.module.scss';

const Radios = props => {
  const { className, direction, title, name, onChange, options, required, checked, theme } = props;
  const cnRadios = cn(
    styles.radios,
    styles[direction],
    className
  );

  const cnLabel = cn(styles.title, {
    [styles.required]: required
  });

  return (
    <div className={cnRadios}>
      {title && (
        <h4 className={cnLabel}>
          {title}
        </h4>
      )}
      {options && (
        Children.toArray(options.map((option, index) => {
          const value = option.value ? option.value : option.label;
          return React.cloneElement(
            <Radio
              className={styles.radio}
              name={name}
              id={`${name}-${index}`}
              label={option.label}
              value={value}
              checked={checked === value}
              disabled={option.disabled}
              onChange={onChange}
              theme={theme}
              required={required}
              tooltipContent={option.tooltipContent}
            />
          );
        }))
      )}
    </div>
  );
};

Radios.displayName = 'Radios';

Radios.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  required: PropTypes.bool,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string
    )
  ])
};

Radios.defaultProps = {
  className: '',
  direction: 'horizontal',
  title: '',
  required: false,
  checked: '',
  touched: true,
  onChange: null
};

export default memo(Radios);
