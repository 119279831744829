import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Burger.module.scss';

const Burger = ({ onChange, on, className }) => {
  return (
    <div className={className}>
      <label className={styles.burger}>
        <input
          type="checkbox"
          checked={on}
          onChange={onChange}
        />
        <div>
          <div>
            <span />
            <span />
          </div>
          <svg>
            <use xlinkHref="#path" />
          </svg>
          <svg>
            <use xlinkHref="#path" />
          </svg>
        </div>
      </label>
      <svg style={{ display: 'none' }}>
        <symbol viewBox="0 0 44 44" id="path">
          <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22" />
        </symbol>
      </svg>
    </div>
  )
};

Burger.displayName = 'Burger';

Burger.propTypes = {
  onChange: PropTypes.func.isRequired,
  on: PropTypes.bool.isRequired,
  className: PropTypes.string
};

Burger.defaultProps = {
  className: null
}

export default memo(Burger);
