import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useMeasure from '../hooks/useMeasure';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

import styles from './Chips2.module.scss';

const Chips2 = forwardRef(({ label, className, onClick, theme, size, status, maxWidth, id, ...rest }, ref) => {
  const [measure, refMeasure] = useMeasure();

  const cnChips = cn(
    styles.chips,
    styles[theme],
    styles[size],
    styles[status],
    className
  );

  const cnSpan = cn(
    styles.span,
    styles[theme],
    styles[size],
    styles[status], {
      [styles.maxWidth]: maxWidth
    }
  );

  const initTooltip = useMemo(
    () => maxWidth <= measure?.width,
    [maxWidth, measure.width]
  );

  const handleClick = useCallback(
    (id, value) => onClick({ id, value }),
    [onClick]
  )

  const styleSpan = useMemo(
    () => ({ maxWidth: !maxWidth ? 'auto' : maxWidth }),
    [maxWidth]
  );

  const displayChips = () => (
    <div ref={refMeasure}>
      <div className={cnChips} ref={ref} {...rest}>
        <div className={cnSpan} style={styleSpan}>{label}</div>
        {onClick && (
          <Icon
            className={styles.close}
            label="times"
            width={12}
            onClick={handleClick}
          />
        )}
      </div>
    </div>
  );

  return !initTooltip
    ? displayChips()
    : (
      <Tooltip content={label}>
        {displayChips()}
      </Tooltip>
    );
});

Chips2.displayName = 'Chips2';

Chips2.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'status']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  status: PropTypes.oneOf(['draft', 'ready', 'current', 'end', 'archived']),
  maxWidth: PropTypes.string
};

Chips2.defaultProps = {
  className: undefined,
  onClick: null,
  theme: 'default',
  size: 'small',
  maxWidth: '130px'
};

export default memo(Chips2);
