import React, {
  cloneElement,
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from '../Button2';
import useMeasure from '../hooks/useMeasure';

import styles from './Dropdown2.module.scss';

const Dropdown2 = ({
  children,
  trigger,
  buttonLabel,
  label,
  labelPosition,
  placeholder,
  position,
  className,
  width,
  size,
  disabled,
  onOpen,
  maxHeight,
  fullWidth,
  optionMaxWidth,
  icon,
  iconTheme,
  iconFill,
  iconSize,
}) => {
  const [measure, ref] = useMeasure();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onOpen) onOpen(open);
  }, [onOpen, open]);

  const handleOpen = useCallback(() => {
    if (disabled) return false;
    setOpen(prevState => !prevState);
  }, [disabled]);

  const handleClose = useCallback(() => setOpen(false), []);

  const collapseStyle = useMemo(() => {
    const isTop = position.includes('top');
    const isBottom = position.includes('bottom');
    const isLeft = position.includes('left');
    const isRight = position.includes('right');

    return Object.assign(
      {
        maxWidth: measure?.width > optionMaxWidth ? '100%' : optionMaxWidth,
        width: !width
          ? measure?.width > optionMaxWidth
            ? '100%'
            : 'auto'
          : `${width}px`
      },
      isTop && { bottom: size === 'big' ? '42px' : '46px' },
      isBottom && {
        top: size === 'big' ? 42 : 46
      },
      isLeft && { left: 0 },
      isRight && { right: 0 }
    );
  }, [width, size, measure, position, optionMaxWidth]);

  const renderTrigger = useMemo(() => {
    if (trigger) {
      return cloneElement(trigger, {
        ref,
        onClick: handleOpen
      });
    }

    const cnButton = cn(styles.button, {
      [styles.open]: open
    });

    return (
      <Button
        ref={ref}
        className={cnButton}
        reverse
        icon={icon}
        iconTheme={iconTheme}
        iconFill={iconFill}
        iconSize={iconSize}
        label={placeholder || buttonLabel}
        onClick={handleOpen}
        theme="secondary"
        size={size}
        active={open}
        disabled={disabled}
        fullWidth={fullWidth}
      />
    );
  }, [
    ref,
    handleOpen,
    trigger,
    buttonLabel,
    placeholder,
    open,
    disabled,
    size,
    fullWidth,
    icon,
    iconTheme,
    iconFill,
    iconSize
  ]);

  const styleItems = useMemo(
    () => ({ maxHeight }),
    [maxHeight]
  );

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cnWrapper}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.triggerWrapper}>
          {renderTrigger}
          <div className={styles.collapse} style={collapseStyle}>
            <Collapse in={open} unmountOnExit timeout={100}>
              <div className={styles.collapseWrapper}>
                <div className={styles.items} style={styleItems}>
                  {children}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Dropdown2.displayName = 'Dropdown2';

Dropdown2.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  placeholder: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onOpen: PropTypes.func,
  maxHeight: PropTypes.number,
  optionMaxWidth: PropTypes.number,
  icon: PropTypes.string,
  iconTheme: PropTypes.string,
  iconFill: PropTypes.string,
  iconSize: PropTypes.string,
};

Dropdown2.defaultProps = {
  size: 'medium',
  position: 'bottom-left',
  labelPosition: 'left',
  placeholder: '',
  fullWidth: false,
  maxHeight: 182,
  optionMaxWidth: 100,
  icon: 'caret-circle-down',
  iconTheme: 'solid',
  iconFill: '#0061AC',
  iconSize: 'huge',
};

export default memo(Dropdown2);
