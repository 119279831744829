import React, { memo } from 'react';
import cn from 'classnames';

import Divider from '../Divider'

import styles from './Modal2.module.scss';

const ModalFooter2 = props => {
  const { children, className } = props;

  const cnModalFooter = cn(styles.modalFooter, className);

  return (
    <div {...props} className={cnModalFooter}>
      <Divider size="small" />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
};

export default memo(ModalFooter2);
