import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../../Icon';

import styles from './BigRadio.module.scss';

const BigRadio = ({
  checked,
  className,
  disabled,
  id,
  itemId,
  label,
  name,
  onChange,
  required,
  ...rest
}) => {
  const cnBigRadio = cn(styles.bigRadio, className, {
    [styles.checked]: checked,
    [styles.required]: required,
    [styles.disabled]: disabled
  });

  const icon = useMemo(
    () => checked ? (
      <Icon label="check" theme="regular" fill="#fff" />
    ) : null,
    [checked]
  );

  return (
    <div className={cnBigRadio}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      {icon}
      <input
        {...rest}
        type="Radio"
        aria-describedby={label}
        checked={checked}
        data-id={itemId}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

BigRadio.displayName = 'BigRadios';

BigRadio.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemId: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

BigRadio.defaultProps = {
  checked: false,
  className: undefined,
  labelClassName: undefined,
  disabled: false,
  itemId: '',
  label: null,
  name: null,
  required: false,
  onChange: () => {}
};

export default memo(BigRadio);
