/* eslint-disable no-unused-expressions */
import { useRef, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (launch = false) => {
  const [size, setSize] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new ResizeObserver(entries => {
      setSize(entries[0].target.getBoundingClientRect());
    })
  );

  useEffect(() => {
    const { current } = observer;
    if (node && launch) {
      current && current.observe(node);
    }

    return () => current && current.disconnect();
  }, [node, observer, launch]);

  return [setNode, size];
};

export default useResizeObserver;
