/* eslint-disable */
import { useState, useCallback, useRef, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useMeasure = (trigger) => {
  const observer = useRef(null);

  const [size, setSize] = useState({
    x: 0,
    y: 0,
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  });

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  const newRef = useCallback(
    node => {
      if (observer.current) observer.current?.disconnect();
      observer.current = new ResizeObserver(entries => {
        if (entries[0].contentBoxSize) {
          setSize(entries[0].target.getBoundingClientRect())
        }
      })
      if (node || (node && trigger)) observer.current?.observe(node);
    },
    [observer, trigger]
  );

  useEffect(() => {
    return () => disconnect();
  }, [disconnect]);

  return [size, newRef];
};

export default useMeasure;
