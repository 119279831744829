import React, {
  Children,
  cloneElement,
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Portal from '@material-ui/core/Portal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from '../Button2';
import Input2 from '../Input2';
import useMeasure from '../hooks/useMeasure';
import useSearch from '../hooks/useSearch';

import styles from './Dropdown.module.scss';

const Dropdown = ({
  options,
  renderItem,
  onSelectItem,
  selected,
  trigger,
  label,
  labelPosition,
  placeholder,
  position,
  className,
  width,
  size,
  disabled,
  onOpen,
  maxHeight,
  fullWidth,
  optionMaxWidth,
  search,
  searchKey,
  inputSearchPlaceholder,
  disablePortal,
  themeCollapse,
}) => {
  const [open, setOpen] = useState(false);
  const [measure, ref] = useMeasure(open);

  const {
    searchQuery,
    handleChange,
    result
  } = useSearch({
    data: options,
    key: searchKey
  });

  useEffect(() => {
    if (onOpen) onOpen(open);
  }, [onOpen, open]);

  const handleOpen = useCallback(() => {
    if (disabled) return false;
    setOpen(prevState => !prevState);
  }, [disabled]);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClickItem = useCallback(
    async item => {
      if (item.disabled) return null;
      if (onSelectItem) await onSelectItem(item);
      if (item?.onClick) await item?.onClick(item);
      handleClose();
    },
    [onSelectItem, handleClose]
  );

  const handleKeyPress = useCallback(
    e => {
      switch (e.key) {
        // switch to build complete key action in futur
        case 'Escape': return handleClose();
        default: return null;
      }
    },
    [handleClose]
  );

  const collapseStyle = useMemo(
    () => {
      const isTop = position.includes('top');
      const isBottom = position.includes('bottom');
      const isLeft = position.includes('left');
      const isRight = position.includes('right');

      const getWidth = {
        maxWidth: measure?.width > optionMaxWidth ? '100%' : optionMaxWidth,
        width: !width ? 'auto' : `${width}px`
      }

      const noPortal = Object.assign(
        { ...getWidth },
        isTop && { bottom: size === 'big' ? '42px' : '46px' },
        isBottom && {
          top: size === 'big'
            ? 42
            : size === 'small'
              ? 38
              : 46
        },
        isLeft && { left: 0 },
        isRight && { right: 0 }
      );

      const withPortal = Object.assign(
        { ...getWidth },
        isTop && { bottom: measure?.top },
        isBottom && { top: measure?.bottom + 4 },
        (isLeft || isRight) && { left: measure?.left },
      )

      return disablePortal ? noPortal : withPortal;
    },
    [width, size, measure, position, optionMaxWidth, disablePortal]
  );

  const selectedOption = useMemo(
    () => options?.find(option => option.value === selected),
    [selected, options]
  );

  const renderTrigger = useMemo(
    () => {
      if (trigger) {
        return cloneElement(trigger, {
          ref,
          onClick: handleOpen
        });
      }

      const cnButton = cn(styles.button, {
        [styles.open]: open
      });

      return (
        <Button
          ref={ref}
          className={cnButton}
          reverse
          icon="caret-circle-down"
          iconTheme="solid"
          iconFill="#0061AC"
          iconSize="huge"
          label={selectedOption?.label || placeholder || label}
          onClick={handleOpen}
          theme="secondary"
          size={size}
          active={open}
          disabled={disabled}
          fullWidth={fullWidth}
        />
      );
    },
    [
      ref,
      handleOpen,
      trigger,
      label,
      placeholder,
      open,
      disabled,
      size,
      fullWidth,
      selectedOption
    ]
  );

  const renderInputSearch = useMemo(
    () => search && (
      <div className={styles.input}>
        <Input2
          placeholder={inputSearchPlaceholder}
          value={searchQuery}
          onChange={handleChange}
          fullWidth
          theme="secondary"
        />
      </div>
    ),
    [search, handleChange, searchQuery, inputSearchPlaceholder],
  );

  const getDataSource = useMemo(
    () => search ? result : options,
    [search, result, options]
  );

  const styleItems = useMemo(
    () => ({ maxHeight: `${maxHeight}px` }),
    [maxHeight]
  );

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  const cnCollapse = cn(styles.collapseContainer, {
    [styles.left]: position.includes('left') && !disablePortal,
  });

  const cnCollapseWrapper = cn(styles.collapseWrapper, styles[themeCollapse]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cnWrapper} onKeyPress={handleKeyPress}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.triggerWrapper}>
          {renderTrigger}
          <Portal disablePortal={disablePortal}>
            <div className={styles.collapse} style={collapseStyle}>
              <div className={cnCollapse}>
                <Collapse in={open} unmountOnExit timeout={100}>
                  <div className={cnCollapseWrapper}>
                    {renderInputSearch}
                    <div className={styles.items} style={styleItems}>
                      {Children.toArray(
                        getDataSource?.map(item => {
                          const el = renderItem(item);
                          const cnItem = cn(
                            styles.item,
                            {
                              [styles.selected]: item.value === selected,
                              [styles.disabled]: item.disabled
                            },
                            el?.props?.className
                          );
                          return cloneElement(el, {
                            className: cnItem,
                            id: parseInt(item.id, 10),
                            onClick: () => handleClickItem(item)
                          });
                        })
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </Portal>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  onSelectItem: PropTypes.func,
  selected: PropTypes.any,
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  placeholder: PropTypes.string,
  position: PropTypes.string,
  renderItem: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onOpen: PropTypes.func,
  maxHeight: PropTypes.number,
  optionMaxWidth: PropTypes.number,
  disablePortal: PropTypes.bool,
  search: PropTypes.bool,
  inputSearchPlaceholder: PropTypes.string,
  searchKey: PropTypes.string,
  themeCollapse: PropTypes.oneOf(['primary', 'secondary']),
};

Dropdown.defaultProps = {
  options: [],
  size: 'medium',
  position: 'bottom-left',
  labelPosition: 'left',
  placeholder: '',
  fullWidth: false,
  renderItem: item => <span key={item.value}>{item.label || item.value}</span>,
  maxHeight: 182,
  optionMaxWidth: 200,
  disablePortal: true,
  search: false,
  inputSearchPlaceholder: null,
  themeCollapse: 'primary',
  searchKey: 'value',
};

export default memo(Dropdown);
