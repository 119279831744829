import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';
import PortalContainer from '../Portal/PortalContainer';
import { StringOrReact } from '../utils/propTypes';

import styles from './InputGmap.module.scss';

const mapGmapToApi = data => {
  const findComponent = (address, type, key = 'short_name') => {
    if (!address) return '';
    const obj = address.find(a => a.types.indexOf(type) > -1);
    if (obj) return obj[key] || '';
    return '';
  };

  return data ? ({
    g_street_number: findComponent(data.address_components, 'street_number'),
    g_street: findComponent(data.address_components, 'route'),
    g_city: findComponent(data.address_components, 'locality'),
    g_state: findComponent(data.address_components, 'administrative_area_level_1'),
    g_zip_code: findComponent(data.address_components, 'postal_code'),
    g_country: findComponent(data.address_components, 'country', 'long_name'),
    g_country_code: findComponent(data.address_components, 'country'),
    g_geometry_location: data.geometry && data.geometry.location ? {
      lat: data.geometry.location.lat(),
      lng: data.geometry.location.lng()
    } : null,
    g_place_id: data.place_id || '',
    g_formatted_address: data.formatted_address
  }) : {};
};

export const mapper = {
  fromApi: ({ gmaps }) => ({
    placeSearched: gmaps ? gmaps.g_formatted_address || '' : ''
  }),
  toApi: gmap => !gmap ? {} : mapGmapToApi(gmap)
};

const loadScript = url => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.addEventListener('load', () => resolve(script));
    script.addEventListener('error', () => reject(script));
    document.body.appendChild(script);
  });
};

class InputSearchLocation extends PureComponent {
  keyActive = -1;
  service = null;
  autocompleteService = null;
  timer = null;

  state = {
    displayPredictions: false,
    predictions: []
  };

  componentDidMount = () => {
    const { gmapApiKey } = this.props;
    if (!window.scriptLoading) {
      window.scriptLoading = true;
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${gmapApiKey}&libraries=places`);
    }
  };

  getInfoOnLocation = (placeId, placeName) => {
    const { onChange } = this.props;

    if (!this.service) {
      this.service = new window.google.maps.places
        .PlacesService(document.createElement('div'));
    }

    this.service.getDetails({ placeId }, place => {
      onChange({
        placeSearched: placeName,
        infoLocation: place,
        mapped: mapper.toApi(place)
      });
    })
  };

  triggerAutoComplete = value => () => {
    const { google } = window;

    if (!google || !value) return null;

    if (!this.autocompleteService)
      this.autocompleteService = new google.maps.places.AutocompleteService();

    const { extraProperties } = this.props;

    this.autocompleteService.getPlacePredictions({ input: value, ...extraProperties },
      (prediction, status) => {
        let predictions = [];
        const index = predictions.findIndex(element =>
          element.description?.toLowerCase() === value.trim().toLowerCase());

        const selectedId = index !== -1 ? predictions[index].place_id : null;

        if (status === 'OK') {
          predictions = prediction;
          this.setState({ predictions });
        }

        if (selectedId !== null)
          this.getInfoOnLocation(selectedId, value);
      }
    )
  };

  selectItemFromList = index => () => {
    const target = this.state.predictions[index];
    const newValue = target.description;
    const selectedId = target.place_id;
    this.getInfoOnLocation(selectedId, newValue);
    this.keyActive = index;
    this.setState({ displayPredictions: false });
  };

  handleKeyPress = ({ keyCode: key }) => {
    if (this.state.predictions.length === 0 || (key !== 38 && key !== 40)) return;
    if (key === 38) this.keyActive -= 1;
    if (key === 40) this.keyActive += 1;
    if (this.keyActive >= this.state.predictions.length) this.keyActive = 0;
    if (this.keyActive < 0) this.keyActive = this.state.predictions.length - 1;

    const selectedId = this.state.predictions[this.keyActive].place_id;
    const newValue = this.state.predictions[this.keyActive].description;
    this.getInfoOnLocation(selectedId, newValue);

    this.setState({ displayPredictions: true });
  };

  handleChange = ({ value: newValue }) => {
    const { onChange } = this.props;

    if (!newValue || newValue === '') {
      this.setState({ displayPredictions: false, predictions: [] });
    }
    if (!this.state.displayPredictions) this.setState({ displayPredictions: true });

    onChange({
      placeSearched: newValue,
      mapped: {}
    });

    if (this.delayType) {
      clearTimeout(this.delayType);
      this.delayType = null;
    }

    this.delayType = setTimeout(this.triggerAutoComplete(newValue), 300);
  };

  handleFocus = () => {
    this.setState({ displayPredictions: true });
  };

  handleBlur = () => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(
      () => this.setState({ displayPredictions: false }),
      200
    );
  };

  handleEnter = () => {
    this.setState({ displayPredictions: false })
  };

  inPortal = () => {
    const { displayPredictions, predictions } = this.state;

    return displayPredictions && predictions?.length > 0 && (
      <div className={styles.predictions}>
        <ul>
          {predictions.map((prediction, index) =>
            <li
              key={index}
              className={this.keyActive === index ? 'active' : null}
              onClick={this.selectItemFromList(index)}
            >
              {prediction.description}
            </li>)}
        </ul>
      </div>
    );
  };

  render() {
    const { value, ...rest } = this.props;
    const { displayPredictions } = this.state;

    return (
      <PortalContainer html={this.inPortal()} on={displayPredictions}>
        <Input
          {...rest}
          value={value}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onEnterPress={this.handleEnter}
          autoComplete="off"
          fullWidth
        />
      </PortalContainer>
    )
  }
}

InputSearchLocation.displayName = 'InputSearchLocation';

InputSearchLocation.propTypes = {
  value: PropTypes.string,
  label: StringOrReact,
  placeholder: PropTypes.string,
  gmapApiKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  extraProperties: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.oneOf([
      'geocode',
      'address',
      'regions',
      'cities',
      'locality',
      'sublocality',
      'postal_code',
      'country',
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3'
    ])),
    radius: PropTypes.number,
    offset: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      noWrap: PropTypes.bool
    })
  })
};

InputSearchLocation.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  disabled: false,
  extraProperties: {}
};

export default InputSearchLocation;
