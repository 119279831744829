/* eslint-disable react/prop-types */
import React, { Children, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from './BarChart.module.scss';

const style = (percent, bg) => ({
  width: `${percent}%`,
  background: bg,
  height: '100%'
});

const BarChart = ({ data, label, labelWidth, totalPercent, totalWidth, className, ...rest }) => {
  const percent = useCallback(
    value => `${value} %`,
    []
  );

  const renderBar = useMemo(
    () => Children.toArray(data.map(
      item => (
        <Tooltip content={percent(item.percent)}>
          <div
            className={styles.segment}
            style={style(item.percent, item.color)}
          />
        </Tooltip>
      ))),
    [data, percent]
  );

  const styleLabel = useMemo(
    () => ({ minWidth: labelWidth }),
    [labelWidth]
  );

  const styleTotal = useMemo(
    () => ({ minWidth: totalWidth }),
    [totalWidth]
  );

  const cnBarChart = cn(styles.container, className);

  return (
    <div className={cnBarChart} {...rest}>
      <div className={styles.wrapper}>
        <span style={styleLabel}>
          {label}
        </span>

        <div className={styles.bar}>
          {renderBar}
        </div>

        <span className={styles.totalPercent} style={styleTotal}>
          {percent(totalPercent)}
        </span>
      </div>
    </div>
  )
};

BarChart.displayName = 'BarChart';

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      percent: PropTypes.number,
      color: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  totalPercent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  labelWidth: PropTypes.number,
  totalWidth: PropTypes.number,
  className: PropTypes.string
};

BarChart.defaultProps = {
  labelWidth: 38,
  totalWidth: 55,
  className: null
};

export default memo(BarChart);
