/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ToggleButton from '../ToggleButton';
import styles from '../ToggleButton/ToggleButton.module.scss';

const ToggleButtonGroup = ({
  className,
  direction,
  disabled,
  error,
  onChange,
  options,
  required,
  selectedOption,
  touched,
  ref,
}) => {
  const handleChange = e => {
    const isChecked = selectedOption.indexOf(e.currentTarget.value) > -1;
    let selected;

    !isChecked ?
      selected = [...selectedOption, e.currentTarget.value] :
      selected = selectedOption.filter(option => option !== e.currentTarget.value);

    return onChange({
      item: e.currentTarget.value,
      selected,
      type: 'toggle-buttons'
    })
  };

  const classNames = cn(
    styles.toggleButtonGroup,
    styles[direction],
    className,
  );

  return (
    <div className={classNames}>
      {options && React.Children.toArray(options.map(option => {
        const { id, value, label } = option;
        return (
          <ToggleButton
            ref={ref}
            id={id}
            name={value}
            label={label}
            value={value}
            checked={selectedOption.indexOf(value) > -1}
            disabled={disabled}
            onChange={handleChange}
            required={required}
          >
            {label}
          </ToggleButton>
        );
      }))}
      {touched && error && error.length > 0 && (
        <span className={styles.error}>{error}</span>
      )}
    </div>
  );
};

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

ToggleButtonGroup.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf([PropTypes.string])
  ]),
  touched: PropTypes.bool,
};

ToggleButtonGroup.defaultProps = {
  className: '',
  direction: 'horizontal',
  disabled: false,
  error: '',
  required: false,
  selectedOption: '',
  touched: true,
  onChange: null,
};

export default ToggleButtonGroup;
