import React, { memo, useCallback, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { animated, useTransition, useChain, config } from 'react-spring';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Icon from '../Icon';
import Portal from '../Portal';

import styles from './Dialog.module.scss';


const Dialog = forwardRef(({ children, on, className, onEnterPress, onKeyPress, onClickOutside, onClose, ...rest }, ref) => {
  const overlayRef = useRef();
  const transitions = useTransition(on, null, {
    unique: true,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  const modalRef = useRef();
  const trans = useTransition(on, null, {
    unique: true,
    from: { transform: `translate3d(0,100%,0)` },
    enter: { transform: 'translate3d(0,0,0)' },
    leave: { transform: `translate3d(0,100%,0)` },
    config: { mass: 0.7, tension: 500, friction: 38 }
  });

  useChain(on ? [overlayRef, modalRef] : [modalRef, overlayRef], [0, on ? 0.1 : 0.6]);

  const cnDialog = cn(styles.dialog, className);

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') onEnterPress(e);
      if (onKeyPress && e.key !== 'Enter') onKeyPress(e);
    },
    [onEnterPress, onKeyPress]
  );

  return (
    <Portal>
      {transitions.map(({ item, key, props }) => (
        item ? (
          <animated.div ref={ref} key={key} style={props} className={styles.overlay} onKeyPress={handleKeyPress} tabIndex="0">
            {trans.map(({ item: itemBody, key: keyBody, props: propsBody }) => itemBody ? (
              <animated.div key={keyBody} style={propsBody} className={styles.container}>
                <ClickAwayListener onClickAway={onClickOutside}>
                  <div className={cnDialog} {...rest}>
                    <Icon
                      label="times"
                      className={styles.icon}
                      onClick={onClose}
                      size="small"
                      isButton
                    />
                    {children}️
                  </div>
                </ClickAwayListener>
              </animated.div>
            ) : null)}
          </animated.div>
        ) : null
      ))}
    </Portal>
  )
});

Dialog.displayName = 'Dialog';

Dialog.propTypes = {
  on: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onClickOutside: PropTypes.func,
  onEnterPress: PropTypes.func,
  onKeyPress: PropTypes.func,
};

Dialog.defaultProps = {
  className: null,
  onClose: () => {},
  onClickOutside: () => {},
  onEnterPress: () => {},
  onKeyPress: () => {},
};

export default memo(Dialog);
