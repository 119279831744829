import React, {memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import cn from 'classnames';

import Icon from '../Icon';
import Portal from '../Portal';
import styles from './SnackBar.module.scss';

const SnackBar = ({ on, className, timeout, onClick, children, ...rest }) => {
  const displaySnackBar = useTransition(on, null, {
    config: { tension: 500, friction: 35, mass: 1, precision: 0.00001 },
    from: { transform: 'translateX(120%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(120%)' },
    unique: true
  });

  const handleClick = useCallback(
    () => onClick(),
    [onClick]
  );

  const timer = useMemo(
    () => setTimeout(handleClick, timeout),
    [handleClick, timeout]
  );

  useEffect(() => {
    (() => {
      if (timeout !== undefined && on) {
        clearTimeout(timeout);
        return timer;
      }
    })();
    return () => clearTimeout(timeout);
  }, [timeout, on, timer]);

  const cnSnackBar = cn(
    styles.snackbar,
    className
  );

  return (
    <Portal>
      {displaySnackBar.map(({ item, key, props }) => item &&
        <animated.div
          key={key}
          className={cnSnackBar}
          role="contentinfo"
          style={props}
          {...rest}
        >
          <div>{children}</div>
          <Icon label="times" onClick={handleClick} />
        </animated.div>)}
    </Portal>
  );
};

SnackBar.displayName = 'SnackBar';

SnackBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  timeout: PropTypes.number
};

SnackBar.defaultProps = {
  className: '',
  timeout: 7000
};

export default memo(SnackBar);
